import { render, staticRenderFns } from "./smsInput.vue?vue&type=template&id=f35bed22&scoped=true"
import script from "./smsInput.vue?vue&type=script&lang=js"
export * from "./smsInput.vue?vue&type=script&lang=js"
import style0 from "./smsInput.vue?vue&type=style&index=0&id=f35bed22&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f35bed22",
  null
  
)

export default component.exports