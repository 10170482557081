<template>
  <div class="platformManagement">
    <div class="searchCondition">
      <div class="searchConditionItem">
        accessKey：
        <a-input
          v-model="accessKeySearch"
          placeholder="请输入accessKey"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        账号名称：
        <a-input
          v-model="platformNameSearch"
          placeholder="请输入账号名称"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        启用状态：
        <a-select v-model="searchEnabled" @change="inquire(1)">
          <a-select-option
            v-for="(item, index) in enabledList"
            :key="index"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        代理通道：
        <a-checkbox v-model="useAgentProxy" @change="inquire(1)" />
      </div>
      <div class="searchConditionItem">
        活跃通道：
        <a-checkbox v-model="active" @change="inquire(1)" />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
        <a-button type="primary" @click="addSign">新增</a-button>
      </div>
    </div>
    <!-- 平台列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(platformColumns)"
      :columns="platformColumns"
      :dataSource="platformTableData"
      :pagination="platformPagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="connectCount" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            最大连接数量:{{ text }}
            <br />
            提交速度:{{ record.cmppSpeed }}条/s
          </template>

          <a href="javascript:;" @click="loadChannelInfo(record)">{{
            record.connectionNum
          }}</a
          >/{{ text }} | {{ record.cmppSpeed }}
        </a-tooltip>
        <a-button
          type="link"
          size="small"
          icon="edit"
          @click="dynamicModifySpeed(record)"
        />
      </span>
      <span slot="loadSpeedTitle">
        实时速度
        <a-tooltip>
          <template slot="title"> 取近5秒的平均数 </template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
      <span slot="loadSpeed" slot-scope="text, record">
        <a-tooltip v-if="text">
          <template slot="title">
            实时提交速度:{{ text?.in5s }}
            <br />
            实时回执速度:{{ text?.out5s }}
          </template>
          {{ text?.in5s }} | {{ text?.out5s }}
        </a-tooltip>
      </span>
      <span slot="canSendApiSms" slot-scope="text">
        <span v-if="text">是</span>
        <span v-else>否</span>
      </span>
      <span slot="canSendBatchSms" slot-scope="text">
        <span v-if="text">是</span>
        <span v-else>否</span>
      </span>
      <span slot="enabled" slot-scope="text, record">
        <a-popconfirm
          title="客户端会被禁用， 是否确定?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="enabledChange(record)"
          v-if="text"
        >
          <a-switch
            checked-children="已启用"
            un-checked-children="已禁用"
            v-model="text"
          />
        </a-popconfirm>
        <a-switch
          v-if="!text"
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="text"
          @change="enabledChange(record)"
        />
      </span>
      <span slot="useAgentProxy" slot-scope="text, record">
        <a-switch
          checked-children="已开启"
          un-checked-children="已关闭"
          v-model="text"
          @change="updateAgentEnableStatus(record)"
        />
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="textSend(record)">测试发送</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="userRelationClick(record)"
                >绑定用户</a
              >
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="modifyPrice(record)">设置价格</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="modifyBlackListSource(record)"
                >黑名单来源</a
              >
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="modifyCmppProxy(record)"
                >设置代理</a
              >
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="deleteClick(record)"
              >
                <template slot="title"
                  >是否确认删除 <b>{{ record.name }}</b></template
                >
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <h1>回执积压数: {{ analysisDeliveryRequestLag }}</h1>
    <h1>当前服务器IP: 114.55.102.7</h1>
    <!-- 新增 -->
    <a-modal
      :title="platformTitle"
      v-model="addPlatformShow"
      :maskClosable="false"
      :afterClose="addPlatformClose"
      class="actionSub-class"
      width="700px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="账号名称" required>
          <a-input
            v-model="platformName"
            placeholder="请输入账号名称"
            :disabled="!enabled"
          />
        </a-form-item>
        <a-form-item label="code" required v-if="platformTitle == '新增平台'">
          <a-select
            v-model="code"
            placeholder="请选择code"
            class="select-tag"
            :disabled="!enabled"
          >
            <a-select-option
              v-for="i in platformList"
              :key="i.id"
              :value="i.code"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item required>
          <span slot="label">
            频次限制
            <a-tooltip>
              <template slot="title"> 自动过滤最近已发送成功号码 </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-select v-model="limit" placeholder="请选择频次" class="select-tag">
            <a-select-option
              v-for="i in limitList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-tabs v-model="platformType">
          <a-tab-pane key="CMPP" tab="CMPP">
            <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
              <a-form-item label="ip" required>
                <a-input
                  v-model="cmppIp"
                  placeholder="请输入ip"
                  :disabled="!enabled"
                />
              </a-form-item>
              <a-form-item label="端口" required>
                <a-input
                  v-model="cmppPort"
                  placeholder="请输入端口"
                  :disabled="!enabled"
                />
              </a-form-item>
              <a-form-item label="接入号" required>
                <a-input
                  v-model="spNo"
                  placeholder="请输入接入号"
                  :disabled="!enabled"
                />
              </a-form-item>
              <a-form-item label="速度" required>
                <a-input
                  v-model="cmppSpeed"
                  placeholder="请输入速度"
                  :disabled="!enabled"
                />
              </a-form-item>
              <a-form-item label="连接数" required>
                <a-input
                  v-model="connectCount"
                  placeholder="请输入连接数"
                  :disabled="!enabled"
                />
              </a-form-item>
            </a-form>
          </a-tab-pane>
        </a-tabs>
        <a-form-item label="账号" required>
          <a-input
            v-model="accessKey"
            placeholder="请输入账号"
            :disabled="!enabled"
          />
        </a-form-item>
        <a-form-item label="密码" required>
          <a-input
            v-model="accessSecret"
            placeholder="请输入密码"
            :disabled="!enabled"
          />
        </a-form-item>
        <a-form-item label="滑动窗口大小" required>
          <a-input
            v-model="windowSize"
            placeholder="请输入滑动窗口大小"
            :disabled="!enabled"
          />
        </a-form-item>
        <a-form-item label="是否启用">
          <div class="switch-button">
            <a-switch
              checkedChildren="启用"
              unCheckedChildren="禁用"
              v-model="enabled"
            />
          </div>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addPlatformShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addPlatform_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 测试发送 -->
    <a-modal
      :title="'【' + platformName + '】'"
      v-model="smsInputShow"
      :maskClosable="false"
      :afterClose="smsInputClose"
      class="actionSub-class"
      width="1000px"
    >
      <a-tabs v-model="smsKey" @change="callback">
        <a-tab-pane key="1" tab="测试发送">
          <a-form>
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
              label="账号名称"
            >
              <span style="font-weight: bold">{{ platformName }}</span>
            </a-form-item>
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
              label="accessKey"
            >
              <span style="font-weight: bold">{{ accessKey }}</span>
            </a-form-item>
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
              label="手机号"
            >
              <a-mentions
                v-model="mobile"
                @select="onMobileSelect"
                @focus="onModileFocus"
                prefix="1"
                placeholder="请输入手机号"
                style="width: 300px"
                allowClear
              >
                <a-mentions-option
                  v-for="(item, index) in testNumberSearch"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.value }}
                </a-mentions-option>
              </a-mentions>
            </a-form-item>
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
              label="短信内容"
            >
              <sms-input
                :textareaSMS="textareaSMS"
                :isDisabled="isDisabled"
                :longUrlValue="longUrlValue"
                :prefixValue="prefixValue"
                :urlFormat="urlFormat"
                :urlTitleValue="urlTitleValue"
                :urlTitleShow="true"
                :domain="selectDomain"
                :selectDomainList="selectDomainList"
                @selectDomainChange="selectDomainChange"
                @change="textarea_sms"
                @longUrlChange="longUrlChange"
                @prefixChange="prefixChange"
                @urlTitleChange="urlTitleChange"
                @urlFormatChange="urlFormatChange"
                :shortUrlShow="false"
              />
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="2" tab="测试记录" force-render>
          <a-table
            bordered
            :rowClassName="$common.rowClassColor"
            :components="$common.getTitle(columnsDetailsTable)"
            :columns="columnsDetailsTable"
            :dataSource="detailsTable"
            :pagination="paginationDetails"
            size="small"
            :rowKey="(record) => record.id"
          >
            <span slot="content" slot-scope="text, record" class="slotCell">
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span v-if="record.short_url">
                    {{ record.contentStart }}
                    <a :href="record.short_url_blank" target="_blank">{{
                      record.short_url
                    }}</a>
                    {{ record.contentEnd }}
                  </span>
                  <span v-else>{{ record.content }}</span>
                </template>
                <span class="content">{{ text }}</span>
              </a-tooltip>
              <a
                href="javascript:;"
                class="copy-class"
                v-clipboard:copy="record.content"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-if="record.isCopyShow"
                >复制</a
              >
            </span>
            <span slot="status" slot-scope="text, record" style="width: 100%">
              <a-tag v-if="record.sendStatusTag === 'orange'" color="orange">{{
                text
              }}</a-tag>
              <a-tag v-if="record.sendStatusTag === 'green'" color="green">{{
                text
              }}</a-tag>
              <a-tag v-if="record.sendStatusTag === 'red'" color="red">{{
                text
              }}</a-tag>
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>

      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="smsInputShow = false">取消</a-button>
          <a-button v-if="smsKey == '1'" type="primary" @click="smsInput_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 绑定用户 -->
    <a-modal
      :title="userRelationTitle"
      v-model="userRelationShow"
      :maskClosable="false"
      :afterClose="userRelationClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="选择用户"
        >
          <a-select
            showSearch
            mode="multiple"
            option-filter-prop="children"
            :filterOption="filterOption"
            v-model="userLoginNames"
            placeholder="请选择用户"
            allowClear
          >
            <a-select-option
              v-for="item in userLoginNameList"
              :key="item.id"
              :value="item.id"
              >{{ item.userLoginName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="签名"
        >
          <a-input v-model="signBind" placeholder="请输入签名" allowClear />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="userRelationShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="userRelation_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="设置黑名单来源"
      v-model="modifyBlackListSourceShow"
      :maskClosable="false"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          label="黑名单来源"
          required
        >
          <a-select
            v-model="blackListSource"
            placeholder="请选择类型"
            class="select-tag"
            mode="multiple"
          >
            <a-select-option
              v-for="(i, index) in typeList"
              :key="index"
              :value="i.code"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyBlackListSourceShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="modifyBlackListSource_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="三网价格"
      v-model="modifyPriceShow"
      :maskClosable="false"
      class="actionSub-class"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="价格:" required>
          <a-input v-model="cmccPrice" placeholder="请输入价格" allowClear />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyPriceShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="modifyPrice_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="动态调整cmpp提交速度"
      v-model="dynamicModifySpeedShow"
      :maskClosable="false"
      class="actionSub-class"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="速度:">
          <a-input
            v-model="dynamicModifySpeedData.cmppSpeed"
            placeholder="请输入速度"
          />
        </a-form-item>
        <a-form-item label="连接数:">
          <a-input
            v-model="dynamicModifySpeedData.maxChannelCount"
            placeholder="请输入连接数"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="dynamicModifySpeedShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="dynamicModifySpeedSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="'通道信息 - ' + platformName"
      v-model="channelInfoShow"
      :footer="null"
      :maskClosable="false"
      width="1200px"
      :afterClose="channelInfoClose"
    >
      <div class="searchCondition">
        <div class="searchButton">
          <a-button type="primary" @click="loadChannelInfo()">刷新</a-button>
        </div>
      </div>
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(channelInfoColumns)"
        :columns="channelInfoColumns"
        :dataSource="channelInfoTableData"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="speedInFiveSecond" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">
              实时提交速度:{{ record.speedInFiveSecond }}
              <br />
              实时回执速度:{{ record.speedOutFiveSecond }}
            </template>
            <a-button
              type="link"
              size="small"
              @click="loadChannelMetricsInfoList(record)"
            >
              {{ record.speedInFiveSecond }}
            </a-button>
            |
            <a-button
              type="link"
              size="small"
              @click="loadChannelMetricsInfoList(record)"
            >
              {{ record.speedOutFiveSecond }}
            </a-button>
          </a-tooltip>
        </span>
        <span slot="channelWritableRate" slot-scope="text, record">
          <a-button
            type="link"
            size="small"
            @click="loadChannelMetricsInfoList(record)"
          >
            {{
              record.channelWritableRate == -1
                ? "-"
                : (record.channelWritableRate * 100).toFixed(2) + "%"
            }}
          </a-button>
        </span>
      </a-table>
    </a-modal>

    <a-modal
      title="设置代理"
      v-model="modifyCmppProxyShow"
      :maskClosable="false"
      class="actionSub-class"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="cmpp代理" required>
          <a-select
            v-model="cmppProxyId"
            placeholder="请选择cmpp代理"
            showSearch
            option-filter-prop="children"
          >
            <a-select-option
              v-for="item in cmppProxyList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyCmppProxyShow = false"
            >取消</a-button
          >
          <a-button key="submit" type="primary" @click="modifyCmppProxySubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="通道性能监控"
      v-model="loadChannelMetricsInfoShow"
      :maskClosable="false"
      :footer="null"
      width="1100px"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          时间：
          <a-select
            v-model="rangeTimeSelect"
            style="width: 130px"
            @change="loadChannelMetricsInfoList()"
          >
            <a-select-option
              v-for="(item, index) in rangeTimeSelectList"
              :key="index"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
          <a-range-picker
            v-if="rangeTimeSelect == 'random'"
            v-model="rangeTime"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            style="width: 360px; margin-left: 5px"
            showTime
          ></a-range-picker>
        </div>
        <div class="searchButton">
          <a-button
            type="primary"
            @click="loadChannelMetricsInfoList()"
            icon="sync"
            :loading="loadChannelMetricsInfoListLoading"
          >
            刷新
          </a-button>
        </div>
      </div>
      <div id="loadChannelMetricsInfo" style="height: 500px"></div>
    </a-modal>
  </div>
</template>
<script>
import smsInput from "../tool/smsInput.vue";
import * as echarts from "echarts";
import moment from "moment";
export default {
  name: "signatureManagement",
  components: {
    smsInput,
  },
  data() {
    return {
      signBind: "",
      loading: false,
      msgPlatformId: "",
      userRelationShow: false,
      userRelationTitle: "",
      userLoginNames: [],
      userLoginNameList: [],
      selectDomain: undefined,
      selectDomainList: [],
      limit: -1,
      limitList: [
        {
          label: "一天",
          value: 86400,
        },
        {
          label: "两天",
          value: 172800,
        },
        {
          label: "三天",
          value: 259200,
        },
        {
          label: "七天",
          value: 604800,
        },
        {
          label: "不限制",
          value: -1,
        },
      ],
      cmppPort: "",
      cmppIp: "",
      cmppSpeed: "100",
      connectCount: "1",
      strategy: undefined,
      async: false,
      strategyList: [
        { key: "RANDOM", value: "随机" },
        { key: "POLLING", value: "轮训" },
      ],
      spNo: "",
      platformType: "CMPP",
      platformList: [],
      signCurrentPage: 1,
      testNumberSearch: [],
      smsInputShow: false,
      textareaSMS: "",
      mobile: "",
      isDisabled: false,
      longUrlValue: "",
      prefixValue: "https://",
      urlFormat: false,
      urlTitleValue: "",
      platformTitle: "",
      canSendApiSms: false,
      canSendBatchSms: false,
      platformNameSearch: "",
      searchEnabled: "",
      enabledList: [
        { label: "全部", value: "" },
        { label: "启用", value: "true" },
        { label: "未启用", value: "false" },
      ],
      accessKeySearch: "",
      active: false,
      useAgentProxy: false,
      platformColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "账号名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "平台编号",
          ellipsis: true,
          dataIndex: "code",
          width: 200,
        },
        {
          title: "accessKey",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 100,
        },
        {
          title: "连接数量",
          ellipsis: true,
          dataIndex: "connectCount",
          scopedSlots: { customRender: "connectCount" },
          width: 150,
        },
        {
          ellipsis: true,
          dataIndex: "loadSpeed",
          scopedSlots: { customRender: "loadSpeed", title: "loadSpeedTitle" },
          width: 150,
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          align: "center",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "代理状态",
          ellipsis: true,
          dataIndex: "useAgentProxy",
          align: "center",
          scopedSlots: { customRender: "useAgentProxy" },
          width: 100,
        },
        {
          title: "上次连接时间",
          ellipsis: true,
          dataIndex: "lastConnectedAt",
          width: 150,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 150,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      platformTableData: [],
      platformPagination: {},
      loadingTable: false,
      addPlatformShow: false,
      enabled: true,
      accessKey: "",
      platformId: "",
      platformName: "",
      platformCode: "",
      code: undefined,
      accessSecret: "",
      windowSize: 32,
      currentPage: 1,
      pageSize: 10,
      isModifyClick: false,
      accessKeySms: "",
      accessKeySmsId: "",
      columnsDetailsTable: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          width: 100,
        },
        {
          title: "内容",
          ellipsis: true,
          dataIndex: "contentLabel",
          scopedSlots: { customRender: "content" },
          customCell: this.mouseoverDetailsContent,
          width: 200,
        },
        {
          title: "发送状态",
          align: "center",
          ellipsis: true,
          dataIndex: "statusLabel",
          scopedSlots: { customRender: "status" },
          width: 90,
        },
        {
          title: "账号通道",
          align: "center",
          ellipsis: true,
          dataIndex: "accountAccessKey",
          width: 100,
        },
        {
          title: "真实状态",
          align: "center",
          ellipsis: true,
          dataIndex: "receiveRealStatus",
          width: 90,
        },
        {
          title: "提交时间",
          align: "center",
          ellipsis: true,
          dataIndex: "submitTime",
          width: 150,
        },
        {
          title: "创建时间",
          align: "center",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 150,
        },
      ],
      detailsTable: [],
      paginationDetails: {},
      smsKey: "1",
      blackListSource: [],
      typeList: [],
      modifyBlackListSourceShow: false,
      modifyPriceShow: false,
      channelSmsAccountId: "",
      channelId: "",
      cmccPrice: 0,
      dynamicModifySpeedData: {},
      dynamicModifySpeedShow: false,
      speedTimer: null,
      analysisDeliveryRequestLag: "",
      analysisDeliveryRequestLagTimer: null,
      channelInfoShow: false,
      channelInfoTableData: [],
      channelInfoColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 80,
        },
        {
          title: "通道id",
          ellipsis: true,
          dataIndex: "channelId",
          width: 100,
        },
        {
          title: "代理名称",
          ellipsis: true,
          dataIndex: "agentName",
          width: 200,
        },
        {
          title: "本地地址",
          ellipsis: true,
          dataIndex: "localAddress",
          width: 160,
        },
        {
          title: "远程地址",
          ellipsis: true,
          dataIndex: "remoteAddress",
          width: 160,
        },
        {
          title: "实时速度",
          ellipsis: true,
          dataIndex: "speedInFiveSecond",
          scopedSlots: { customRender: "speedInFiveSecond" },
          width: 120,
        },
        {
          title: "有效写入率",
          ellipsis: true,
          dataIndex: "channelWritableRate",
          scopedSlots: { customRender: "channelWritableRate" },
          width: 100,
        },
        {
          title: "连接时长",
          ellipsis: true,
          width: 80,
          dataIndex: "timeDifference",
        },
        {
          title: "上次心跳时间",
          ellipsis: true,
          dataIndex: "lastHeartbeatAtTime",
          width: 100,
        },
      ],
      channelInfoTimeDifferenceTimer: null,
      cmppProxyId: -1,
      cmppProxyList: [],
      modifyCmppProxyShow: false,
      rangeTimeSelectList: [
        { label: "最近两分钟", value: 2 },
        { label: "最近五分钟", value: 5 },
        { label: "最近十五分钟", value: 15 },
        { label: "自定义", value: "random" },
      ],
      rangeTimeSelect: 2,
      rangeTime: [],
      loadChannelMetricsInfoShow: false,
      loadChannelMetricsInfoListLoading: false,
      chart: null,
    };
  },
  mounted() {
    this.getTypeList();
    this.getAnalysisDeliveryRequestLag();
    let useAgentProxy = localStorage.getItem("useAgentProxy");
    if (useAgentProxy) {
      this.useAgentProxy = JSON.parse(useAgentProxy);
    }
    this.inquire(1);
  },
  methods: {
    loadChannelInfo(record) {
      if (record) {
        this.platformName = record.name;
        this.channelSmsAccountId = record.id;
      }
      let data = { channelSmsAccountId: this.channelSmsAccountId };
      this.$http.get("/msgPlatform/loadChannelInfo", data).then((res) => {
        if (res.result === 200) {
          this.channelInfoTableData = res.data;

          this.channelInfoTableData.forEach((item) => {
            const currentTime = new Date(); // 当前时间
            const diffInMs = currentTime - new Date(item.gmtCreated); // 计算时间差，单位为毫秒

            // 将毫秒转换为时分秒
            const hours = Math.floor(diffInMs / (1000 * 60 * 60));
            const minutes = Math.floor(
              (diffInMs % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

            // 格式化为 HH:mm:ss
            const formattedHours = String(hours).padStart(2, "0");
            const formattedMinutes = String(minutes).padStart(2, "0");
            const formattedSeconds = String(seconds).padStart(2, "0");

            let timeDifference = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
            let lastHeartbeatAtTime = (
              (currentTime - new Date(item.lastHeartbeatAt)) /
              1000
            ).toFixed(0);

            this.$set(item, "timeDifference", timeDifference);
            this.$set(
              item,
              "lastHeartbeatAtTime",
              lastHeartbeatAtTime > 0 ? lastHeartbeatAtTime + "s" : "0s"
            );
          });
          this.channelInfoShow = true;
          if (!this.channelInfoTimeDifferenceTimer && this.channelInfoShow) {
            this.channelInfoTimeDifferenceTimer = setInterval(() => {
              this.loadChannelInfo();
            }, 2000);
          }
        }
      });
    },
    channelInfoClose() {
      clearInterval(this.channelInfoTimeDifferenceTimer);
      this.channelInfoTimeDifferenceTimer = null;
    },
    // 加载数据
    loadChannelMetricsInfoList(record) {
      this.loadChannelMetricsInfoListLoading = true;
      if (record) {
        this.channelId = record.channelId;
        this.rangeTimeSelect = 2;
      }
      if (this.rangeTimeSelect !== "random") {
        this.rangeTime = [
          moment(new Date())
            .subtract(this.rangeTimeSelect, "minute")
            .format("YYYY-MM-DD HH:mm:ss"),
          moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        ];
      }

      let data = {
        channelSmsAccountId: this.channelSmsAccountId,
        channelId: this.channelId,
        startTime: this.rangeTime[0],
        endTime: this.rangeTime[1],
      };

      this.$http
        .get("/msgPlatform/loadChannelMetricsInfoList", data)
        .then((res) => {
          if (res.result === 200) {
            const groupedData = {};
            res.data.forEach((item) => {
              if (!groupedData[item.gmtCreated]) {
                groupedData[item.gmtCreated] = { gmtCreated: item.gmtCreated };
              }
              groupedData[item.gmtCreated][item.tag] = item.value;
            });

            // 计算 WRITABLERATE
            const chartData = Object.values(groupedData).map((item) => ({
              gmtCreated: item.gmtCreated,
              SPEED_IN: item.SPEED_IN || 0,
              SPEED_OUT: item.SPEED_OUT || 0,
              WRITABLERATE: item.TOTAL_WRITE_TIME
                ? (
                    ((item.TOTAL_WRITE_TIME - (item.UNWRITABLE_TIME || 0)) /
                      item.TOTAL_WRITE_TIME) *
                    100
                  ).toFixed(2)
                : "0.00",
            }));

            this.initChart(chartData);
          }
        })
        .catch(() => {
          this.loadChannelMetricsInfoListLoading = false;
        });
    },
    // 初始化/更新图表
    initChart(chartData) {
      this.loadChannelMetricsInfoShow = true;
      this.$nextTick(() => {
        if (!this.chart) {
          // 第一次创建图表
          this.chart = echarts.init(
            document.getElementById("loadChannelMetricsInfo")
          );
        }

        const option = {
          title: {
            show: false,
          },
          tooltip: {
            trigger: "axis",
            formatter: (params) => {
              let result = params[0].axisValueLabel + "<br />";
              params.forEach((param) => {
                result += `${param.seriesName}: ${param.value[1]}${
                  param.seriesName === "有效写入率" ? "%" : ""
                }<br />`;
              });
              return result;
            },
          },
          legend: {
            data: ["回执速度", "提交速度", "有效写入率"],
            bottom: 0,
          },
          grid: {
            left: "5%",
            right: "5%",
            bottom: "15%",
          },
          xAxis: {
            type: "time",
            name: "时间",
            axisLabel: {
              formatter: "{HH}:{mm}:{ss}",
            },
            splitLine: { show: false },
          },
          yAxis: [
            {
              type: "value",
              name: "回执速度/提交速度",
              min: 0,
              axisLabel: { formatter: "{value}" },
            },
            {
              type: "value",
              name: "有效写入率 (%)",
              min: 0,
              max: 100,
              axisLabel: { formatter: "{value}%" },
              position: "right",
            },
          ],
          series: [
            {
              name: "回执速度",
              type: "line",
              data: chartData.map((item) => [item.gmtCreated, item.SPEED_IN]),
              color: "#1890ff",
              smooth: true,
              showSymbol: false,
            },
            {
              name: "提交速度",
              type: "line",
              data: chartData.map((item) => [item.gmtCreated, item.SPEED_OUT]),
              color: "#52c41a",
              smooth: true,
              showSymbol: false,
            },
            {
              name: "有效写入率",
              type: "line",
              yAxisIndex: 1,
              data: chartData.map((item) => [
                item.gmtCreated,
                parseFloat(item.WRITABLERATE),
              ]),
              color: "#ff4d4f",
              smooth: true,
              showSymbol: false,
            },
          ],
          animation: false, // 关闭动画
        };

        // 更新数据而不重新创建实例
        this.chart.setOption(option);
        this.loadChannelMetricsInfoListLoading = false;
      });
    },
    dynamicModifySpeed(record) {
      this.dynamicModifySpeedData = {
        channelSmsAccountId: record.id,
        cmppSpeed: record.cmppSpeed,
        maxChannelCount: record.connectCount,
      };
      this.dynamicModifySpeedShow = true;
    },
    dynamicModifySpeedSubmit() {
      let data = { ...this.dynamicModifySpeedData };

      this.$http.json_post("/sms/dynamicModifySpeed", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.dynamicModifySpeedShow = false;
          this.inquire();
        }
      });
    },
    // tabs发生变化
    callback(key) {
      this.smsKey = key;
    },
    // 获取测试记录
    getTestSms(index) {
      let data = {
        pageNo: index || 1,
        pageSize: 10,
        id: this.accessKeySmsId,
      };
      this.$http.get("/sms/testSmsPage", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.isCopyShow = false;
            item.statusLabel = this.stateHandling2(item.sendStatus); // 状态处理
            let sendStatusList = [
              "SUBMIT_FAILURE",
              "FAILURE",
              "BLANK_NUMBER",
              "DOWNTIME",
              "WRONG_NUMBER",
              "BLACKLIST",
              "UNKNOWN_OPERATOR",
              "OVER_SEND",
              "ILLEGAL_SIGN",
              "OTHER",
              "UNKNOWN",
            ];
            if (sendStatusList.indexOf(item.sendStatus) > -1) {
              item.sendStatusTag = "red";
            }
            if (item.sendStatus === "SUCCESS") {
              item.sendStatusTag = "green";
            }
            if (
              item.sendStatus === "WAIT_SUBMIT" ||
              item.sendStatus === "SUBMITTING" ||
              item.sendStatus === "SUBMIT_SUCCESS"
            ) {
              item.sendStatusTag = "orange";
            }
            item.contentLabel = item.content;
            item.contentStart = item.content;
            item.contentEnd = "";
            item.short_url = "";
            let shortUrl = "";
            if (item.params) {
              shortUrl = JSON.parse(item.params)["#短链#"];
            }
            if (shortUrl) {
              if (item.content.indexOf(shortUrl) > -1) {
                let short_url_blank = "";
                shortUrl.indexOf("https://") > -1 ||
                shortUrl.indexOf("http://") > -1
                  ? (short_url_blank = shortUrl.trim())
                  : (short_url_blank = "https://" + shortUrl.trim());
                item.short_url_blank = short_url_blank;
                item.short_url = shortUrl;
                let length = shortUrl.length - 1;
                item.contentStart = item.content.substr(
                  0,
                  item.content.indexOf(shortUrl)
                );
                item.contentEnd = item.content.substr(
                  item.content.indexOf(shortUrl) + length
                );
              }
            }
            if (item.content.length > 25) {
              item.contentLabel = item.content.substr(0, 25) + "...";
            }
          });
          this.detailsTable = list;
          this.paginationDetails = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_details(current),
          };
        }
      });
    },
    currentPage_details(index) {
      this.getTestSms(index);
    },
    stateHandling2(val) {
      switch (val) {
        case "WAIT_SUBMIT":
          return "等待提交";
        case "SUBMITTING":
          return "提交中";
        case "SUBMIT_SUCCESS":
          return "提交成功";
        case "SUBMIT_FAILURE":
          return "提交失败";
        case "SUCCESS":
          return "发送成功";
        case "FAILURE":
          return "发送失败";
        case "BLANK_NUMBER":
          return "空号";
        case "DOWNTIME":
          return "停机";
        case "WRONG_NUMBER":
          return "手机号格式错误";
        case "BLACKLIST":
          return "黑名单";
        case "UNKNOWN_OPERATOR":
          return "未知运营商";
        case "OVER_SEND":
          return "超频";
        case "ILLEGAL_SIGN":
          return "非法签名";
        case "OTHER":
          return "其它";
        case "UNKNOWN":
          return "未知状态";
      }
    },
    // 详情表-鼠标移入长链列
    mouseoverDetailsContent(record, rowIndex) {
      return {
        on: {
          // 事件
          click: (event) => {}, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {
            // 鼠标移入行
            this.detailsTable.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.detailsTable[rowIndex], "isCopyShow", true);
              }
            });
          },
          mouseleave: (event) => {
            // 鼠标移出行
            this.detailsTable.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.detailsTable[rowIndex], "isCopyShow", false);
              }
            });
          },
        },
      };
    },
    // 复制成功
    onCopy(e) {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError(e) {
      this.$message.error("复制失败");
    },
    enabledChange(record) {
      let data = {
        id: record.id,
        enabled: !record.enabled,
      };
      this.$http
        .json_post("/msgPlatform/updateEnableStatus", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            setTimeout(() => {
              this.inquire();
            }, 500);
          }
        });
    },
    // 查询用户列表
    getUserList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      this.$http.json_post("/user/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.userLoginNameList = list;
        }
      });
    },
    // 查询绑定用户列表
    getUserNameList(id) {
      let data = {
        channelSmsAccountId: id,
      };
      this.$http.get("/platformUserRelation/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.userLoginNames = list;
        }
      });
    },
    // 点击绑定用户
    userRelationClick(val) {
      this.getUserList();
      this.userRelationShow = true;
      this.userRelationTitle = "【" + val.name + "】绑定用户";
      this.msgPlatformId = val.id;
    },
    // 确定绑定用户
    userRelation_submit() {
      let data = {
        channelSmsAccountId: this.msgPlatformId,
        userIdSet: this.userLoginNames,
        sign: this.signBind,
      };
      this.loading = true;
      this.$http
        .json_post("/platformUserRelation/update", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("绑定成功");
            this.userRelationShow = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭绑定用户
    userRelationClose() {
      this.signBind = "";
      this.userLoginNames = [];
      this.userLoginNameList = [];
    },
    modifyPrice(val) {
      this.channelSmsAccountId = val.id;
      let data = {
        channelSmsAccountId: this.channelSmsAccountId,
      };
      this.$http
        .get("/msgPlatform/loadChannelPriceSetting", data)
        .then((res) => {
          if (res.result === 200) {
            if (res.data) {
              this.cmccPrice = res.data.cmccPrice;
            } else {
              this.cmccPrice = 0;
            }
            this.modifyPriceShow = true;
          }
        });
    },
    modifyPrice_submit() {
      let data = {
        channelSmsAccountId: this.channelSmsAccountId,
        cmccPrice: this.cmccPrice,
      };
      this.$http.json_post("/msgPlatform/modifyPrice ", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("设置成功");
          this.modifyPriceShow = false;
          this.inquire(1);
        }
      });
    },
    getTypeList() {
      this.$http.get("/blacklist/typeList").then((res) => {
        if (res.result === 200) {
          this.typeList = res.data;
        }
      });
    },
    getAnalysisDeliveryRequestLag() {
      this.$http.get("/report/analysisDeliveryRequestLag").then((res) => {
        if (res.result === 200) {
          this.analysisDeliveryRequestLag = res.data;
          if (this.analysisDeliveryRequestLagTimer) {
            clearTimeout(this.analysisDeliveryRequestLagTimer);
            this.analysisDeliveryRequestLagTimer = null;
          }
          this.analysisDeliveryRequestLagTimer = setTimeout(() => {
            this.getAnalysisDeliveryRequestLag();
          }, 2000);
        }
      });
    },
    modifyBlackListSource(val) {
      this.channelSmsAccountId = val.id;
      let data = {
        channelSmsAccountId: this.channelSmsAccountId,
      };
      this.$http
        .get("/msgPlatform/loadChannelBlacklistSetting", data)
        .then((res) => {
          if (res.result === 200) {
            this.blackListSource = res.data;
            this.modifyBlackListSourceShow = true;
          }
        });
    },
    modifyCmppProxy(val) {
      this.channelSmsAccountId = val.id;
      this.cmppProxyId = val.cmppProxyId;
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      this.$http.get("/cmppProxyInfo/cmppProxyInfoPage", data).then((res) => {
        if (res.result === 200) {
          this.cmppProxyList = res.data.records;
          this.cmppProxyList.unshift({ id: -1, name: "无" });
          this.modifyCmppProxyShow = true;
        }
      });
    },
    modifyCmppProxySubmit() {
      let data = {
        channelSmsAccountId: this.channelSmsAccountId,
        cmppProxyId: this.cmppProxyId,
      };
      this.$http.json_post("/cmppProxyInfo/setProxy", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("设置成功");
          this.modifyCmppProxyShow = false;
          this.inquire();
        }
      });
    },
    updateAgentEnableStatus(record) {
      this.$confirm({
        title: "提示",
        content:
          "是否为" +
          record.name +
          (record.useAgentProxy ? "关闭" : "开启") +
          "代理？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: record.id,
            enabled: !record.useAgentProxy,
          };
          this.$http
            .json_post("/msgPlatform/updateAgentEnableStatus", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success(
                  (record.useAgentProxy ? "关闭" : "开启") + "成功"
                );
                setTimeout(() => {
                  this.inquire();
                }, 500);
              }
            });
        },
        onCancel: () => {},
      });
    },
    deleteClick(record) {
      let data = {
        id: record.id,
      };
      this.$http
        .json_post("/msgPlatform/deleteChannelSmsAccount", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.inquire();
          }
        });
    },
    modifyBlackListSource_submit() {
      let data = {
        id: this.channelSmsAccountId,
        blackListSource: this.blackListSource.join(","),
      };
      this.$http
        .json_post("/msgPlatform/modifyBlackListSource", data)
        .then((res) => {
          if (res.result === 200) {
            this.modifyBlackListSourceShow = false;
            this.$message.success("设置成功");
            this.inquire();
          }
        });
    },
    // 搜索用户
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 查询短链域名
    getDomainList() {
      this.selectDomainList = [];
      this.$http.get("/shortUrl/domainList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.selectDomainList.push({
              value: item,
              label: item,
            });
          });
        }
      });
    },
    // 查询平台列表
    getPlatList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      this.platformList = [];
      this.$http.get("/plat/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.platformList = list;
        }
      });
    },
    // 手机号码提示选择
    onMobileSelect(potion) {
      this.mobile = potion.value;
    },
    // 手机号码提示选择
    onModileFocus(potion) {
      let restaurants = [];
      restaurants = this.$common.getCookie("testModileNumber");
      if (restaurants) {
        this.testNumberSearch = JSON.parse(restaurants);
      } else {
        this.testNumberSearch = [];
      }
    },
    // 点击测试发送
    textSend(val) {
      this.smsKey = "1";
      this.accessKey = val.accessKey;
      this.platformName = val.name;
      this.platformCode = val.code;
      this.smsInputShow = true;
      this.getDomainList();
      this.accessKeySms = val.accessKey;
      this.accessKeySmsId = val.id;
      this.getTestSms();
    },
    // 确定测试发送
    smsInput_submit() {
      let data = {
        accessKey: this.accessKey,
        content: this.textareaSMS,
        mobile: this.mobile,
        platformCode: this.platformCode,
      };
      let checkMobile = this.$common.checkMobile(this.mobile);
      if (!checkMobile) {
        return;
      }
      this.$common.numberSetCookie(this.mobile, "testModile");
      let containShortUrl = this.textareaSMS.indexOf("#短链#") !== -1;
      if (containShortUrl) {
        data.prefix = this.prefixValue;
        data.longUrl = this.longUrlValue;
        data.urlTitle = this.urlTitleValue;
        data.domain = this.selectDomain;
        if (!data.domain) {
          this.$message.warning("请选择域名");
          return;
        }
        if (this.urlFormat) {
          return;
        }
      }
      this.$http.json_post("/sms/testSendSingleSms", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("发送成功");
          this.smsInputShow = false;
        }
      });
    },
    // 关闭短信编辑
    smsInputClose() {
      this.platformName = "";
      this.accessKey = "";
      this.textareaSMS = "";
      this.mobile = "";
      this.urlFormat = false;
      this.isDisabled = false;
      this.longUrlValue = "";
      this.urlTitleValue = "";
      this.prefixValue = "https://";
      this.selectDomain = undefined;
    },
    // 获取短信内容
    textarea_sms(val) {
      this.textareaSMS = val;
    },
    // 获取长链内容
    longUrlChange(val) {
      this.longUrlValue = val;
    },
    // 获取长链内容
    selectDomainChange(val) {
      this.selectDomain = val;
    },
    // 获取长链名称
    urlTitleChange(val) {
      this.urlTitleValue = val;
    },
    // 获取前缀
    prefixChange(val) {
      this.prefixValue = val;
    },
    // 判断长链格式
    urlFormatChange(val) {
      this.urlFormat = val;
    },
    // 点击修改
    modifyClick(val) {
      this.platformTitle = "修改平台";
      this.addPlatformShow = true;
      this.isModifyClick = true;
      this.id = val.id;
      this.accessKey = val.accessKey;
      this.platformName = val.name;
      this.code = val.code;
      this.accessSecret = val.accessSecret;
      this.windowSize = val.windowSize;
      this.enabled = val.enabled;
      this.canSendApiSms = val.canSendApiSms;
      this.canSendBatchSms = val.canSendBatchSms;
      this.platformType = val.platformType;
      this.cmppPort = val.cmppPort;
      this.cmppIp = val.cmppIp;
      this.cmppSpeed = val.cmppSpeed;
      this.connectCount = val.connectCount;
      this.spNo = val.spNo;
      this.limit = val.timeLimit;
      this.getPlatList();
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        name: this.platformNameSearch.trim(),
        accessKey: this.accessKeySearch,
        active: this.active,
        enabled:
          this.searchEnabled == "true"
            ? true
            : this.searchEnabled == "false"
            ? false
            : null,
      };
      if (this.useAgentProxy) {
        data.useAgentProxy = true;
      }
      localStorage.setItem("useAgentProxy", this.useAgentProxy);
      this.getSignList(data);
    },
    // 点击新增
    addSign() {
      this.platformTitle = "新增平台";
      this.addPlatformShow = true;
      this.isModifyClick = false;
      this.getPlatList();
    },
    // 确认新增
    addPlatform_submit() {
      let data = {
        name: this.platformName,
        code: this.code,
        accessKey: this.accessKey,
        accessSecret: this.accessSecret,
        windowSize: this.windowSize,
        enabled: this.enabled,
        platformType: this.platformType,
        cmppPort: this.cmppPort,
        cmppIp: this.cmppIp,
        cmppSpeed: this.cmppSpeed,
        connectCount: this.connectCount,
        spNo: this.spNo,
        timeLimit: this.limit,
      };
      if (!data.name) {
        this.$message.warning("请输入账号名称");
        return;
      }
      if (!data.code) {
        this.$message.warning("请输入code");
        return;
      }
      if (data.platformType === "CMPP") {
        if (!data.cmppPort) {
          this.$message.warning("请输入端口");
          return;
        }
        if (!data.cmppIp) {
          this.$message.warning("请输入cmppIp");
          return;
        }
        if (!data.cmppSpeed) {
          this.$message.warning("请输入cmppSpeed");
          return;
        }
        if (!data.connectCount) {
          this.$message.warning("请输入connectCount");
          return;
        }
        if (!data.spNo) {
          this.$message.warning("请输入接入号");
          return;
        }
      }
      if (this.isModifyClick) {
        // 判断是否为点击修改
        data.id = this.id;
        this.$http.json_post("/sms/platform/update", data).then((res) => {
          if (res.result === 200) {
            this.addPlatformShow = false;
            this.$message.success("修改成功");
            this.inquire();
          }
        });
      } else {
        this.$http.json_post("/sms/platform/add", data).then((res) => {
          if (res.result === 200) {
            this.addPlatformShow = false;
            this.inquire(1);
            this.$message.success("新增成功");
          }
        });
      }
    },
    // 关闭新增
    addPlatformClose() {
      this.accessKey = "";
      this.platformName = "";
      this.code = undefined;
      this.accessSecret = "";
      this.windowSize = 32;
      this.enabled = true;
      this.canSendApiSms = false;
      this.canSendBatchSms = false;
      this.platformType = "CMPP";
      this.cmppPort = "";
      this.cmppIp = "";
      this.cmppSpeed = "100";
      this.connectCount = "";
      this.spNo = "";
      this.limit = -1;
    },
    // 查询签名列表
    getSignList(data) {
      this.loadingTable = true;
      this.$http
        .get("/sms/platform/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            });

            this.platformTableData = list;
            if (this.active) {
              if (!this.speedTimer) {
                this.speedTimer = setInterval(() => {
                  this.inquire();
                }, 3000);
              }
            } else {
              if (this.speedTimer) {
                clearInterval(this.speedTimer);
                this.speedTimer = null;
              }
            }
            this.platformPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.signChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 签名分页
    signChangePage(index) {
      this.currentPage = index;
      this.inquire();
    },
    // 平台启用or禁用
    signOnChange_c(e, val) {
      let title = "启用";
      if (e) {
        title = "启用";
      } else {
        title = "禁用";
      }
      this.$confirm({
        title: "提示",
        content: "是否" + title + "此平台？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          return new Promise((resolve, reject) => {
            this.$http
              .json_post("/sms/platform/update/status", {
                id: val.id,
                enabled: e,
              })
              .then((res) => {
                if (res.result === 200) {
                  if (e) {
                    this.$message.success("启用成功");
                  } else {
                    this.$message.success("禁用成功");
                  }
                  this.inquire();
                  resolve();
                } else {
                  reject();
                }
              })
              .catch((err) => {
                reject();
              });
          }).catch(() => {});
        },
        onCancel: () => {
          for (let i in this.platformTableData) {
            if (this.platformTableData[i].id === val.id) {
              this.$set(this.platformTableData[i], "enabled", !e);
            }
          }
        },
      });
    },
  },
  beforeDestroy() {
    if (this.speedTimer) {
      clearInterval(this.speedTimer);
      this.speedTimer = null;
    }

    if (this.analysisDeliveryRequestLagTimer) {
      clearTimeout(this.analysisDeliveryRequestLagTimer);
      this.analysisDeliveryRequestLagTimer = null;
    }
  },
};
</script>
