<template>
  <div class="shortLinks">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="智能短链">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="普通短链">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="点击数据">
        <router-view name="three" v-if="active == '3'" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="链接分配" v-if="roleCode === 'SUPER_MANAGER'">
        <router-view name="three" v-if="active == '4'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "shortLinks",
  props: ["roleCode"],
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/shortLinks/intelligence") {
          this.active = "1";
        } else if (newVal == "/shortLinks/free") {
          this.active = "2";
        } else if (newVal == "/shortLinks/clickData") {
          this.active = "3";
        } else if (newVal == "/shortLinks/longUrlDistributeInfo") {
          this.active = "4";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/shortLinks/intelligence") return;
          this.$router.replace("/shortLinks/intelligence");
        }
        if (newVal === "2") {
          if (this.$route.path == "/shortLinks/free") return;
          this.$router.replace("/shortLinks/free");
        }
        if (newVal === "3") {
          if (this.$route.path == "/shortLinks/clickData") return;
          this.$router.replace("/shortLinks/clickData");
        }
        if (newVal === "4") {
          if (this.$route.path == "/shortLinks/longUrlDistributeInfo") return;
          this.$router.replace("/shortLinks/longUrlDistributeInfo");
        }
      },
    },
  },
};
</script>
